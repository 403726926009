/* [Table of contents] 
	1. Minxi 
	2. Extend 
	3. Global 
	4. Header 
	5. Footer 
	6. Homepage
	7. Pages 
	8. Shop 
	9. Blog
	10. Portfolio 
	11. Elements  
	12. Responsive 
	13. Font 
 	-------------------------------------------------------------------*/
@import "common/minxi.scss";
/* 2. Extend ------------------------------------------------------------ */
@import "common/extend.scss";
/* 3. Global ------------------------------------------------------------ */
@import "common/global.scss";
/* 4. Header ------------------------------------------------------------ */
@import "layouts/header.scss";
/* 5. Footer ------------------------------------------------------------ */
@import "layouts/footer.scss";
/* Landing ------------------------------------------------------------*/
@import "layouts/page/landing/landing.scss";
/* 6. Homepage ------------------------------------------------------------ */
@import "layouts/page/homepages/hp-1.scss";
/* 7. Pages ------------------------------------------------------------ */
@import "layouts/page/pages/contact.scss";
/* 8. Shop ------------------------------------------------------------ */
@import "layouts/page/shop/types.scss";
@import "layouts/page/shop/pages.scss";
/* 9. Blog ------------------------------------------------------------ */
@import "layouts/page/blog/masonry.scss";
@import "layouts/page/blog/standard.scss";
@import "layouts/page/blog/types.scss";
/* 10. Portfolio ------------------------------------------------------------ */
@import "layouts/page/portfolio/layouts.scss";
@import "layouts/page/portfolio/list.scss";
@import "layouts/page/portfolio/types.scss";
/* 11. Elements ------------------------------------------------------------ */
@import "layouts/page/elements/element.scss";
/* 12. Responsive ------------------------------------------------------------ */
@import "layouts/responsive.scss";
/* 13. Fonts ------------------------------------------------------------ */
@import "layouts/fonts.scss";
