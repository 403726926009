/* Map Section */
.map-section {
    .js-map-holder {
        height: 490px;
    }
}
/* End Map Section */

/* Contact Section */
.contact-section.section-box {
    padding: 113px 0 116px;
}
.contact-section {
    .contact-content {
        .quote-form {
            margin-right: 30px;
            .form-input {
                margin-bottom: 33px;
                input {
                    padding: 0 0 9px 0;
                    border: none;
                    border-bottom: 1px solid #333;
                    @include input-placeholder(#333);
                }
            }
            .form-textarea {
                height: 85px;
                margin-bottom: 50px;
                textarea {
                    padding: 0 0 9px 0;
                    border: none;
                    border-bottom: 1px solid #333;
                    @include textarea-placeholder(#333);
                }
            }
            .form-bottom {
                position: relative;
                width: 185px;
                input {
                    background: transparent;
                    color: #333;
                    border: 1px solid #333;
                    padding: 12px 55px 12px 35px;
                    cursor: pointer;
                    &:hover {
                        background: #333;
                        color: #fff;
                    }
                }
                span {
                    color: #333;
                    position: absolute;
                    top: 50%;
                    @include transform(translateY(-50%));
                    left: 68%;
                }
                &:hover {
                    span {
                        color: #fff;
                    }
                }
            }
        }
        .contact-details {
            margin-left: 30px;
            .special-heading {
                text-align: left;
                font-size: 30px;
                padding-bottom: 9px;
                margin-bottom: 40px;
                &:before {
                    left: 0;
                    @include transform(translateX(0));
                }
            }
            .contact-info {
                @extend display-flex;
                .contact-inner {
                    h3 {
                        font-size: 20px;
                        padding-bottom: 23px;
                    }
                    p {
                        &.center {
                            padding: 15px 0 8px;
                        }
                        i {
                            padding-right: 6px;
                        }
                        a {
                            color: #666;
                            &:hover {
                                color: #333;
                            }
                        }
                    }
                }
            }
            .socials {
                padding-top: 36px;
                a {
                    color: #999;
                    font-size: 20px;
                    padding-right: 19px;
                    &:hover {
                        color: #333;
                    }
                }
            }
        }
    }
}
/* End Contact Section */
