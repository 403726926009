/* Header Desktop hp-1 hp-2 hp-3 hp-6*/
.header-hp-1 {
	&.style-header-hp-1 {
		position: absolute;
	    top: 0;
	    width: 100%;
	    z-index: 9999;
	    background: #fff;
	}
	.menu-desktop {
		padding: 0 65px;
	}
	.menu-desktop-inner {
    	@extend display-flex;
    	@include justify-content(space-between);
    	position: relative;
    	.logo {
			padding: 31px 0 30px;
			height: 100%;
		}
		
	    .main-menu {
	        .menu-item {
	        	color: #333;
	            float: left;
	            font-family: 'Poppins', sans-serif;
	            font-weight: 500;
	            font-size: 15px;
	            padding: 33px 0px 30px 0;
	            position: relative;
	            .current {
	            	position: relative;
	            	&:before {
		            	position: absolute;
		            	content: '';
		            	background: #333;
		            	height: 2px;
		            	width: 30px;
		            	left: 24px;
		            	bottom: 0;
		            	opacity: 1;
	            	}
	            }
	            > a {
	            	color: #333;
	                padding: 0 24px;
	                position: relative;
	            }
	            &:hover {
	                > .sub-menu {
	                    display: block;
	                }
	            }
	            .sub-menu {
	                display: none;
	                // padding: 25px 0;
	                position: absolute;
	                left: 5px;
	                width: 250px;
	                z-index: 99999;
	                top: 100%;
	                color: #333;
	                background: #fff;
	                .sub-menu-item {
	                	float: left;
	                	overflow: hidden;
	                	&.sub-menu-item-1 {
	                		float: none;
	                	}
	                }
	                li {
	                    float: none;
	                    position: relative;
	                    &:hover {
	                    	.menu-levels {
	                    		display: block;
	                    		left: 250px;
	                    		top: 0;
	                    		// padding: 25px 0;
	                    	}
                            a {
                                color: #666;
                                &:hover {
                                	color: #333;
                                }
                            }
	                    }
	                }
	                a {
	                    font-weight: 400;
	                    font-size: 15px;
	                    color: #666;
	                    padding: 8px 29px;
						display: block;
						padding: 15px 0 15px 18px;
	                    position: relative;
	                    i {
	                    	font-size: 17px;
	                    	position: absolute;
	                    	top: 50%;
	                    	@include transform(translateY(-50%));
	                    	right: 30px;
	                    }
	                    &:before {
	                    	position: absolute;
	                    	content: '';
	                    	width: 5px;
	                    	height: 1px;
	                    	background: #333;
	                    	top: 50%;
	                    	@include transform(translateY(-50%));
	                    	left: 30px;
	                    	opacity: 0;
	                    }
	                    &:hover {
	                    	padding-left: 45px;
	                        &:before {
	                        	opacity: 1;
	                        }
	                    }                    
	                }
	            }
	            &.mega-menu {
	            	.sub-menu {
	            		width: 740px;
	            		padding-bottom: 18px;
	            		ul {
	            			float: left;
	            			li {
	            				&.title {
	            					padding: 18px 0 13px 44px;
	            				}
	            				a {
	            					padding: 8px 15px 8px 44px;
	            					&:before {
	            						left: 45px;
	            					}
	            					&:hover {
	            						padding-left: 59px;
	            						padding-right: 0;
	            					}
	            				}
	            			}
	            			&.second {
	            				padding-right: 38px;
	            			}
	            		}
	            		figure {
	            			margin-bottom: 0;
	            			img {
	            				width: 86.5%;
	            			}
	            		}
	            	}
	            	&.mega-menu-1 {
	            		.sub-menu {
	            			padding-top: 15px;
	            			padding-bottom: 0;
	            			.sub-menu-item {
	            				padding-top: 10px;
	            				&.sub-menu-item-1 {
	            					padding-top: 0;
	            				}
	            			}
	            			ul {
	            				&.second {
	            					padding: 0 42px;
	            				}
	            			}
	            			figure {
	            				img {
	            					width: 72.1%;
	            				}
	            			}
	            		}
	            	}
	            }
	            &:last-child {
	            	.sub-menu {
	            		// right: 5px;
	            		left: inherit;
	            	}
	            }
	        }
	    }
  	}
  	.header-right {
  		@extend display-flex;
		@include align-items(center);
  		.search-btn-wrap {
  			.search-btn {
  				@include outline(none);
  				cursor: pointer;
  				background: transparent;
  				border: none;
  			}
  		}
  		.canvas-btn-wrap {
  			.canvas-btn {
  				cursor: pointer;
  				border: none;
  				background: transparent;
  				@include outline(none);
			    margin-top: -5px;
			    margin-right: -6px;
  			}
  			
  		}
  	}
  	.site-header-cart {
		padding: 0px 7px 0 12px;
		position: relative;
		.cart-contents {
			cursor: pointer;
		}
		.cartAmount {
			position: absolute;
			top: 2px;
			left: 86%;
			font-size: 10px;
			font-weight: bold;
		}
		.widget_shopping_cart {
			display: none;
			position: absolute;
		    z-index: 999;
		    right: -6px;
		    top: 100%;
		    width: 350px;
		    margin-top: 30px;
		    background: #fff;
		    .product_list_widget {
			    padding: 40px 30px 0px;
			    .woocommerce-mini-cart-item {
				    position: relative;
				    margin-bottom: 15px;
			        border-bottom: 1px solid #f2f2f2;
					padding-bottom: 15px;
				    .product-image {
					    width: 100px;
					    height: 90px;
					    float: left;
					    margin-right: 17px;
					    border: 1px solid #f2f2f2;
					    position: relative;
					    img {
					    	width: 57%;
						    position: absolute;
						    top: 50%;
						    left: 50%;
						    @include transform(translate(-50%,-50%));
					    }
					    &:hover {
					    	@include box-shadow(0px 2px 5px 0px rgba(0, 0, 0, 0.1));
							border: 1px solid #e5e5e5;
					    }
					}
					.product-title {
						color: #333;
						font-weight: 500;
						margin: 21px 0 4px;
					}
					.quantity {
						display: block;
					}
					.remove {
						position: absolute;
						top: -5px;
						right: 0;
						color: #999;
					}
				}
			}
			.woocommerce-mini-cart__total {
			    color: #333;
			    font-weight: 500;
			    font-size: 17px;
			    @extend display-flex;
			    @include justify-content(space-between);
			    @include align-items(center);
			    margin: -1px 30px 18px;
			    border-bottom: 1px solid #f2f2f2;
			    padding-bottom: 12px;
			}
			.woocommerce-mini-cart__buttons {
				padding: 0 30px 40px;
			    width: 100%;
			    @extend display-flex;
			    @include align-items(center);
			    @include justify-content(center);
			    .btn-small {
				    padding: 17px 31px;
				}
				.checkout {
					&.au-btn {
						border-left: none;
					}
				}
			}
		}
	}
	#searchModal {
		background: #fff;
		button.close {
			color: #666;
		    opacity: 1;
		    font-size: 25px;
		    position: absolute;
		    top: 35px;
			right: 113px;
		    @include outline(none);
		}
		.modal-dialog {
			top: 20%;
			max-width: 779px;
			margin-top: -25px;
			.modal-content {
				border: none;
				@include border-radius(0);
				.modal-body {
					padding: 0;
					#searchModal__form {
						position: relative;
						#searchModal__submit {
							position: absolute;
							background: transparent;
						    border: none;
						    @include outline(none);
						    color: #333;
						    font-size: 20px;
					        right: 10px;
    						margin-top: 2px;
    						cursor: pointer;
						}
						input::placeholder {
							font-size: 20px;
							font-weight: 500;
						}
						input {
							@include input-placeholder(#333);
							padding: 6px 10px;
						    border: none;
						    border-bottom: 1px solid #333;
						}
					}
				}
			}
		}
	}
	.modal {
		overflow-y: hidden;
	}
	#canvasModal {
		left: 77%;
		margin-right: -17px;
		button.close {
			@include outline(none);
		    z-index: 999;
		    position: absolute;
		    top: 36px;
		    right: 80px;
		    opacity: 1;
		    color: #666;
		    font-size: 25px;
		}
		.modal-content {
			border: none;
			@include border-radius(0);
		}
		.modal-body {
			padding: 0;
		}
		.modal-dialog {
			margin: 0;
		}
		.canvas-content {
			display: block;
			position: absolute;
		    top: 0;
		    background: #fff;
		    width: 440px;
		    right: 0;
	        @include box-shadow(0px 0px 5px 0px rgba(0, 0, 0, 0.1));
			padding: 124px 63px 70px;
		    min-height: 100vh;
		    .logo {
			    padding: 31px 0 30px;
			}
			.insta {
				.insta-inner {
				    margin: 7px -5px 10px;
				    display: flex;
				    .images {
					    padding: 0 5px;
					    cursor: pointer;
					    .images-inner {
					    	position: relative;
						    .overlay {
						    	background: rgba(0,0,0,0.2);
						    }
						    &:hover {
						    	.overlay {
						    		opacity: 1;
						    	}
						    }
					    }
					}
				}
			}
			.contact {
				padding-bottom: 17px;
				h4 {
				    padding: 44px 0 17px;
				}
				.contact-inner {
				    @extend display-flex;
				    @include align-items(center);
				    padding-bottom: 9px;
				    i {
				    	padding-right: 15px;
				    }
				    .contact-info {
				    	a {
				    		color: #666;
				    		&:hover {
				    			color: #333;
				    		}
				    	}
				    }
				}
			}
			.email {
				position: relative;
				.send {
					cursor: pointer;
					position: absolute;
					right: 0;
				    top: 50%;
					@include transform(translateY(-50%));
				}
				input::placeholder {
					font-size: 13px;
				}
				input {
					padding: 5px 0;
					border: none;
					border-bottom: 1px solid #666;
				}
			}
			.socials {
				padding: 38px 0 80px;
				a {
					color: #666;
					font-size: 17px;
					padding-right: 14px;
					&:hover {
						color: #333;
					}
				}
			}
		}
	}
}
.modal-backdrop.show {
    opacity: 0;
    left: 77%;
}
/* End Header Desktop hp-1 hp-2 hp-3 hp-6*/

/* Header hp-4 */
.header-hp-1.header-hp-4 {
	.top-header {
		text-align: center;
		padding-top: 41px;
	}
	.menu-desktop-inner {
		.main-menu {
			.menu-item {
				padding: 28px 0px;
			}
		}
	}
	.site-header-cart {
		padding: 27px 0 0;
		.widget_shopping_cart {
			margin-top: 0;
			left: 0;
			right: auto;
		}
	}
}
/* End Header hp-4 */

/* Header hp-5 */
.header-hp-1 {
	&.style-header-hp-1 {
		&.style-header-hp-5 {
			background: transparent;
		}
	}
}
/* End Header hp-5 */

/* Header Page Not Found */
.header-hp-1 {
	&.header-404 {
		.site-header-cart {
		    padding: 0px 0px 0 12px;
		}
	}
}
/* End Header Page Not Found */

/* Header Landing Page */
.header-landing-page {
	display: none;
}
/* End Header Landing Page */

/* Header Mobile */ 
.mobile-top-header {
	padding: 15px 24px 15px 30px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;

    &.style-mobile-top-header {
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    z-index: 99999;
	    background: #fff;
	}
    .logo-mobile {
        display: inline-block;
    }
    /* Hamburger Button */
    .hamburger {
        background: none;
        border: none;
        float: right;
        &:focus {
            outline: none;
        }
        .hamburger-box {
            position: relative;
            display: inline-block;
            width: 40px;
            height: 24px;
            background: none;
            border: none;
            cursor: pointer;
        }
    }
    .hamburger--spin {
        .hamburger-inner {
            transition-timing-function: cubic-bezier(.55, .055, .675, .19);
            transition-duration: .3s;
            position: absolute;
            width: 40px;
            height: 4px;
            transition-property: transform;
            @include border-radius(4px);
            background-color: #000;
            top: 50%;
            display: block;
            margin-top: -2px;
            &:before,
            &:after {
                position: absolute;
                width: 40px;
                height: 4px;
                transition-property: transform;
                @include border-radius(4px);
                background-color: #000;
                content: '';
                display: block;
            }
            &:before {
                top: -10px;
            }
            &:after {
                top: 10px;
            }
        }
    }
    .hamburger--spin.is-active {
        .hamburger-inner {
            @include transition-delay(.14s);
            transition-timing-function: cubic-bezier(.215, .61, .315, 1);
            @include transform(rotate(225deg));
            &:before {
                top: 0;
                @include transition((top .1s ease-out,opacity .1s ease-out .14s));
                opacity: 0;
            }
            &:after {
                top: 0;
                bottom: 0;
                @include transition((bottom .1s ease-out,transform .3s cubic-bezier(.215,.61,.355,1) .14s));
                @include transform(rotate(-90deg));
            }
        }
    }
    /* End Hamburger Button */
}
.au-navbar-mobile {
    display: none;
    width: 100%;
    z-index: 999999;
    &.style-au-navbar-mobile {
	    width: 100%;
	    z-index: 999999;
	    position: absolute;
	    top: 56.89px;
	}
    .au-navbar-menu {
        ul {
            list-style-type: none;
            padding-left: 0;
            margin-bottom: 0;
            li {
                border-top: 1px solid #e9e9e9;
                a {
                    background-color: #fff;
                    display: block;
                    height: 46px;
                    padding: 13px 30px;
                    font-weight: 600;
                    text-transform: uppercase;
                }
                &:last-child {
                	border-bottom: 1px solid #e9e9e9;
                }
            }
            li.drop {
                position: relative;
                z-index: 999999;
                .arrow {
                    @include transition(0.3s);
                    position: absolute;
                    right: 0;
                    top: 0;
                    font-size: 23px;
                    cursor: pointer;
                    padding: 7px 20px;
                }
                .drop-menu {
                    display: none;
                    li {
                        a {
                            padding: 13px 50px;
                            background: #f2f2f2;
                            &.drop-menu-inner {
                            	padding: 13px 30px 13px 80px;
                            }
                        }
                    }
                }
            }
        }
    }
}
/* End Header Mobile */


/* Header Scroll hp-1 hp-2 hp-3 hp-6*/
.header-hp-1 {
    .menu-desktop.fixed {
        z-index: 9999;
        position: fixed;
        width: 100%;
        background: #fff;
        top: 0;
        @include box-shadow(0px 1px 3px 0px rgba(0, 0, 0, 0.1));
    }
}
/* End Header Scroll hp-1 hp-2 hp-3 hp-6*/

/* Header Scroll hp-4 */
.header-hp-1 {
	&.header-hp-4 {
		.menu-desktop.fixed {
			.top-header {
				display: none;
			}
		}
	}
}
/* End Header Scroll hp-4 */

/* Header Scroll hp-5 */
.header-hp-1 {
	&.style-header-hp-5 {
		.menu-desktop.fixed {
			display: none;
		}
	}
}
/* End Header Scroll hp-5 */


.abr-width_100 {
	width: 100%;
}