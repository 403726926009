* {
    @include box-sizing(border-box);
}
body {
    color: #666;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.67;
    font-family: "Poppins", sans-serif;
    word-wrap: break-word;
    overflow-x: hidden;
    background: #fff !important;
}
p {
    margin-bottom: 0;
}
a {
    @include transition(all 0.3s ease 0.1s);
    text-decoration: none;
    display: inline-block;
    color: #333;
    &:hover {
        text-decoration: none;
        color: #333;
    }
    &:focus,
    &:active {
        outline: 0;
    }
}
a img {
    border: none;
}
input[type="submit"] {
    @include transition(0.3s);
}
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #333;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    letter-spacing: 0;
    margin-top: 0;
    margin-bottom: 0;
}
h1 {
    font-size: 50px;
}
h2 {
    font-size: 38px;
}
h3 {
    font-size: 30px;
}
h4 {
    font-size: 22px;
}
h5 {
    font-size: 18px;
}

img {
    max-width: 100%;
    height: auto;
}
img[alt="logo"] {
    height: 26px;
    transform: scale(1.25) translateX(8px) translateY(-1px);
}

iframe {
    border: none;
    vertical-align: top;
}
ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.au-btn {
    @include transition(0.3s);
    border: 1px solid #333;
    display: inline-block;
    line-height: 1;
    @include box-sizing(border-box);
    text-align: center;
    color: #333;
    background: transparent;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    z-index: 999;
    &:before {
        content: "";
        position: absolute;
        z-index: -1;
        @include transition((all 0.5s));
        width: 0%;
        height: 100%;
        top: 0;
        left: 0;
        background: #333;
    }
    &:hover {
        color: #fff;
        text-decoration: none;
        &:before {
            width: 100%;
        }
    }
}
.au-btn-white {
    @include transition(0.3s);
    border: 1px solid #fff;
    display: inline-block;
    line-height: 1;
    @include box-sizing(border-box);
    text-align: center;
    color: #fff;
    background: transparent;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    z-index: 999;
    &:before {
        content: "";
        position: absolute;
        z-index: -1;
        @include transition((all 0.5s));
        width: 0%;
        height: 100%;
        top: 0;
        left: 0;
        background: #333;
    }
    &:hover {
        color: #fff;
        border: 1px solid #333;
        text-decoration: none;
        &:before {
            width: 100%;
        }
    }
}
.au-btn-black {
    @include transition(0.3s);
    border: 1px solid #333;
    display: inline-block;
    line-height: 1;
    @include box-sizing(border-box);
    text-align: center;
    color: #fff;
    background: #333;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-weight: 400;
    position: relative;
    z-index: 999;
    &:before {
        content: "";
        position: absolute;
        z-index: -1;
        @include transition((all 0.5s));
        width: 0%;
        height: 100%;
        top: 0;
        left: 0;
        background: #fff;
    }
    &:hover {
        color: #333;
        text-decoration: none;
        &:before {
            width: 100%;
        }
    }
}
.btn-small {
    padding: 17px 24.5px;
    // text-align: center;
}
.btn-medium {
    padding: 22.5px 53.5px;
}
.btn-large {
    padding: 24px 72px;
}
.more-link {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: #333;
    &:hover {
        color: #dabc95;
    }
}
.section-box {
    padding-top: 150px;
    padding-bottom: 120px;
}
.special-heading {
    font-size: 38px;
    font-weight: 500;
    color: #333;
    text-align: center;
    position: relative;
    padding-bottom: 16px;
    margin-bottom: 63px;
    &:before {
        position: absolute;
        left: 50%;
        @include transform(translateX(-50%));
        content: "";
        background: #333;
        width: 63px;
        height: 3px;
        top: 100%;
    }
}
.onnew,
.onsale {
    color: #fff;
    font-weight: 500;
    letter-spacing: 2px;
    &:hover {
        color: #fff;
    }
}
.onnew {
    background: #88cce2;
    padding: 0 7.5px;
    &:hover {
        background: #5bc1e3;
    }
}
.onsale {
    background: #eba6aa;
    padding: 0 6.5px;
    &:hover {
        background: #eb8f94;
    }
}
.overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    @include transition(all 0.5s ease 0.1s);
    z-index: 9;
}
.breadcrumb-section,
.breadcrumb-menu-section {
    @include background-size(cover);
    background-repeat: no-repeat;
    background-position: center center;
}
.breadcrumb-section.section-box {
    // padding-top: 150px;
    // padding-bottom: 150px;
    padding-top: 90px;
    padding-bottom: 90px;
    background-image: url(/images/1920x330.jpg);
    background-color: #f7f7f7;
    @media (min-width: 1200px) {
        margin-top: 88px;
    }
}
.breadcrumb-section {
    .breadcrumb-inner {
        h1 {
            color: #333;
            font-weight: 500;
            font-size: 45px;
            padding-bottom: 17px;
        }
        .breadcrumbs {
            color: #666;
            font-size: 18px;
            font-weight: 400;
            overflow: hidden;
            li {
                float: left;
            }
            .breadcrumbs-1 {
                color: #666;
                &:after {
                    content: "/";
                    margin: 0 11px;
                    color: #666;
                }
                &:hover {
                    color: #333;
                }
            }
        }
    }
}

/* Back To Top Button */
#back-to-top {
    color: #333;
    position: fixed;
    bottom: 20px;
    right: 20px;
    text-align: center;
    z-index: 9999;
    height: 46px;
    width: 35px;
    background-color: #fff;
    border: 1px solid #333;
    @include transition(background-color 0.3s linear);
    &:before {
        content: "";
        width: 1px;
        height: 18px;
        background: #333;
        position: absolute;
        top: 50%;
        left: 50%;
        @include transform(translate(-50%, -50%));
    }
}
/* End Back To Top Button */

/*Form*/
input,
select,
textarea,
.select-selected {
    width: 100%;
    height: 100%;
    padding: 11px 20px;
    border: 1px solid #e5e5e5;
    @include appearance(unset);
    @include outline(none);
    @include box-sizing(border-box);
    @include border-radius(0);
    color: #666;
    font-size: 15px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
}
input:focus,
select:focus,
textarea:focus {
    @include outline(none);
    @include box-shadow-input(none);
}
input {
    @include input-placeholder(#999);
}
input[type="submit"] {
    width: auto;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    display: none;
}
textarea {
    @include textarea-placeholder(#999);
}

/*Images Loader*/
.images-preloader {
    position: fixed;
    z-index: 100001;
    background-color: #eee;
    width: 100%;
    height: 100%;
}
#preloader_1 {
    position: relative;
}
.rectangle-bounce {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -15px;
    margin-top: -10px;
}
#preloader_1 span {
    display: block;
    bottom: 0px;
    width: 9px;
    height: 5px;
    background: #333;
    position: absolute;
    @include animation(preloader_1 1.5s infinite ease-in-out);
}
#preloader_1 span:nth-child(2) {
    left: 11px;
    @include animation-delay(0.2s);
}
#preloader_1 span:nth-child(3) {
    left: 22px;
    @include animation-delay(0.4s);
}
#preloader_1 span:nth-child(4) {
    left: 33px;
    @include animation-delay(0.6s);
}
#preloader_1 span:nth-child(5) {
    left: 44px;
    @include animation-delay(0.8s);
}
@keyframes preloader_1 {
    0% {
        height: 5px;
        @include transform(translateY(0px));
        background: #333;
    }
    25% {
        height: 30px;
        @include transform(translateY(15px));
        background: #333;
    }
    50% {
        height: 5px;
        @include transform(translateY(0px));
        background: #333;
    }
    100% {
        height: 5px;
        @include transform(translateY(0px));
        background: #333;
    }
}

// Additional edits

.product-page__main-component button:focus {
    outline: none;
}

.woocommerce-review-link {
    cursor: default;
}

.canvas-btn-wrap {
    @media (min-width: 1200px) {
        display: none;
    }
}

// SweetAlert custom styles
.swal-confirm-custom,
.swal-cancel-custom {
    color: transparent;
    height: 50px;
    padding: 0 34px;
    border: 1px solid #333;
    background: white;
    border-radius: 0;
    cursor: pointer;
    position: relative;
}

.swal-cancel-custom {
    border-left: none;
}

.swal-confirm-custom:focus,
.swal-cancel-custom:focus {
    outline: none;
}

.swal-confirm-custom::before,
.swal-cancel-custom::before {
    content: "";
    position: absolute;

    top: 0;
    // z-index: -1;
    width: 0%;
    height: 100%;
    transition-duration: 300ms;
    transition-property: width, background-color;
    transition-timing-function: ease-in-out;
    background-color: #333;
}

.swal-confirm-custom::before {
    left: 0;
}

.swal-cancel-custom::after {
    content: "Cancel";
    position: absolute;
    color: #fff;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    transition-duration: 300ms;
    transition-property: color;
}

.swal-confirm-custom::after {
    content: "OK";
    position: absolute;
    color: #333;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    transition-duration: 300ms;
    transition-property: color;
}

.swal-cancel-custom:hover::after {
    color: #333;
}

.swal-confirm-custom:hover::after {
    color: #fff;
}

.swal-cancel-custom::before {
    content: "";
    right: 0;
    width: 100%;
    // background-color: #fff;
}

.swal-confirm-custom:hover::before {
    width: 100%;
}

.swal-cancel-custom:hover::before {
    width: 0%;
}

// End of SweetAlert custom styles

// 404 Page

section.page-not-found {
    height: 100vh;
    background-size: cover;
    background-position: center;
    // @media (min-width: 1200px) {
    //     margin-top: 88px;
    // }
}

.page-not-found .page-detail-404 {
    height: 100%;
    display: flex;
    align-items: center;
}
.page-inner--404 {
    width: 46%;
    text-align: center;
    // transform: translateY(-50%);
}
h2.title-404 {
    font-size: 60px;
    font-weight: 600;
    color: #fff;
}
p.paragraph-404 {
    // font-weight: 400;
    font-size: 18px;
    padding: 21px 0 61px;
    color: #fff;
}
.err-arrow {
    padding-left: 10px;
    transform: translateY(1px);
}

// Product page bundles

.bundles .special-heading {
    margin: 92px 0 75px;
    text-align: left;
    padding-bottom: 9px;
    font-size: 30px;
    width: 100%;
    @media (max-width: 767px) {
        margin-bottom: calc(42px - 15px);
    }
}

// .bundles__container {
//     // height: 288px;

// }

.bundles .special-heading::before {
    left: calc(63px / 2);
}

.bundles__item-container {
    // background: lighten($color: gray, $amount: 40%);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 10px;
    @media (max-width: 767px) {
        margin-bottom: 30px;
    }
}

.bundles__item-box {
    position: relative;
    box-sizing: border-box;
    height: 288px;
    border: 1px solid #f2f2f2;
    width: calc((100% - 180px) / 4);
    max-width: 260.5px;
    @media (max-width: 1199px) {
        width: calc((100% - 180px) / 3);
    }
    @media (max-width: 991px) {
        height: calc(288px / 1.25);
    }
    @media (max-width: 767px) {
        width: calc((100% - 15px) / 2);
        margin-top: 15px;
    }
    @media (max-width: 575px) {
        width: calc((100% - 15px) / 2);
        margin-top: 15px;
    }
}

.bundles__item-box:not(:last-child) {
    margin-right: 90px;
    @media (max-width: 767px) {
        margin-right: 0px;
    }
}

.bundles__item-box:nth-child(even) {
    @media (max-width: 767px) {
        margin-right: 15px;
    }
}

.bundles__img {
    object-fit: cover;
    max-height: 228px;
    width: 100% !important;
}

.bundles__item-box:not(:first-child):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4))::after {
    content: "+";
    display: flex;
    font-size: 25px;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    position: absolute;

    left: calc(-48px - 21px);
    top: 50%;
    width: calc(60px / 1.25);
    height: calc(60px / 1.25);
    border-radius: calc((60px / 1.25) / 2);
    background-color: white;
    transform: translateX(-0%) translateY(-50%);
    border: 1px solid darken($color: #f2f2f2, $amount: 5%);
    box-shadow: 0 0px 5px rgba($color: #000000, $alpha: 0.1);
    @media (max-width: 767px) {
        display: none;
    }
}

.bundles__text {
    text-align: center;
    width: 100%;
    position: absolute;
    bottom: 0;
    background: white;
    padding: 26px 20px;
    display: flex;
    flex-direction: column;
    transition-property: box-shadow;
    transition-duration: 300ms;
    box-shadow: 0 2 5px rgba($color: #000000, $alpha: 0.1);
    @media (max-width: 575px) {
        // display: none;
    }
}

.bundles__item-box:hover .bundles__text {
    box-shadow: 0 2px 7px rgba($color: #000000, $alpha: 0.15);
}

.break {
    flex-basis: 100%;
    height: 0;
    order: 4;
    @media (max-width: 767px) {
        display: none;
    }
}

.bundles__total-container {
    order: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 75px;
    margin-bottom: 30px;
    border: 1px solid darken($color: #f2f2f2, $amount: 0%);
    // box-shadow: 0 0px 5px rgba($color: #000000, $alpha: 0.1);

    @media (min-width: 767px) {
        padding: 30px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0);
        transition-property: box-shadow;
        transition-duration: 300ms;
        // border: 1px solid darken($color: #f2f2f2, $amount: 5%);
    }
    @media (max-width: 767px) {
        justify-self: center;
        width: calc((100% - 15px) / 2);
        margin-top: 15px;
        margin-bottom: 0;
        flex-direction: column;
        justify-content: center;
        min-height: 228px;
    }
}

.bundles__total-container:hover {
    @media (min-width: 767px) {
        box-shadow: 0 2px 7px rgba($color: #000000, $alpha: 0.1);
    }
}

.bundles__tottal {
    margin-right: 30px;
    @media (min-width: 767px) {
        white-space: nowrap;
    }
    @media (max-width: 767px) {
        text-align: center;
    }
    @media (max-width: 767px) {
        margin-bottom: 30px;
        margin-right: 0;
    }
}

.bundles__btn-buy {
    border-left: 1px solid #333;
    position: relative;
    @media (max-width: 767px) {
    }
}

.bundles__btn-buy::after {
    content: "Buy";
    left: calc(50% - 7px);
}

.bundles__btn-buy .zmdi-arrow-right {
    position: absolute;
    color: white;
    transition-property: color;
    transition-duration: 300ms;
    transform: translateY(-50%);
    top: 50%;
}

.bundles__btn-buy:hover .zmdi-arrow-right {
    color: #333;
}

.bundles__discount-flag {
    font-weight: bold;
    position: absolute;
    top: 15px;
    background: #eba6aa;
    color: white;
    text-align: center;
    width: 60px;
    box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.2);
    transition-property: background-color;
    transition-duration: 300ms;
}

.bundles__discount-flag:hover {
    background: #eb8f94;
}

td.product-name {
    @media (min-width: 767px) {
        white-space: nowrap;
    }
}

td.product-name a {
    @media (min-width: 767px) {
        white-space: normal;
    }
}

// Product page versions

.versions {
    width: 375px;
    @media (max-width: 767px) {
        width: 100%;
    }
}

.versions__wrap {
    position: relative;
}

.versions__title {
    height: 48px;
    display: flex;
    // align-items: flex-end;
    // padding-bottom: calc(37px / 4);
    align-items: center;
}

.versions .product-option {
    cursor: pointer;
    // margin-top: calc(37px / 2);
    border: 1px solid #333;
}

.versions .versions__wrap:first-child {
    margin-top: calc(37px - (37px / 2));
}

.abr-versions__style .versions__wrap:after {
    display: none !important;
}
// .abr-versions__style .vs--open {
//     .vs__selected-options .abr-versions_row img {
//         height: 25px !important;
//     }
// }

.abr-versions_row {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    overflow: hidden;
    img {
        margin-right: 5px;
        object-fit: cover;
    }
}

.abr-versions__style .vs__dropdown-option--highlight {
    background: #994d00 !important;
    color: #fff;
}

.abr_double .vs__search {
    height: 70px;
}

.abr-versions__style .vs__dropdown-menu {
    -ms-overflow-style: none;
    scrollbar-width: none;
    z-index: 999;
    &::-webkit-scrollbar {
        display: none;
    }
}

.versions__wrap:after {
    content: "";
    position: absolute;
    pointer-events: none;
    right: calc(37px / 2);
    transform: rotate(180deg);
    top: calc(70%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent #333 transparent;
}

.menu-item--right:after {
    content: "";
    position: absolute;
    pointer-events: none;
    right: calc((24px / 2) + 0px);
    transform: rotate(180deg);
    top: calc(49%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 4px 4px 4px;
    border-color: transparent transparent lighten($color: #333, $amount: 20%)
        transparent;
}

// cross out price

.crossed-out {
    text-decoration: line-through;
}

// Owl Carousel nav buttons

.bundles .owl-nav {
    top: 0;
    margin-top: 92px !important;
}

.bundles .owl-dots {
    display: block !important;
}

// Categories mobile

.featured-content--mobile .row {
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column-reverse;
    }
}

// Product page, description

.product-description-images img {
    @media (min-width: 768px) {
        max-height: 330px;
    }
    @media (min-width: 992px) {
        max-height: 450px;
    }
    @media (min-width: 1200px) {
        max-height: 570px;
    }
}

// Search

.fixed .modal {
    height: 100vh;
}

#payment-form iframe {
    height: 100% !important;
    min-height: 500px;
}

@media screen and (max-width: 768px) {
    .section-box {
        padding-top: 50px !important;
    }  
}

.padding-10 {
    padding-right: 10px;
}

.height-680 {
    min-height: 680px;
    height: 100%;
}

@media screen and (max-width: 380px) {
    .height-680 {
        height: 100% !important;
    }
    .shop-single-v1-section .woocommerce .summary .cart .btn-small {
        margin-top: 25px;
        width: 100%;
    }
}

// Phone icon + active colors

.active-color {
    font-weight: 500;
    color: #994d00 !important;
}

.active-background {
    background: rgb(153, 77, 0) !important;
}

.active-abr-btn {
    border: 1px solid #994d00;
    color: #fff;
    &:hover {
        border: 1px solid #333;
    }
}

#phone-icon {
    padding: 2px;
    position: fixed;
    bottom: 10px;
    left: 20px;
    z-index: 9999;
    width: 60px;
    height: 60px;

    box-shadow: 0 0 0 0 rgba(153, 77, 0, 0.1);
	transform: scale(1);
    animation: pulse 2s infinite;
    
    border-radius: 100px;
    overflow: hidden;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(153, 77, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(153, 77, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(153, 77, 0, 0);
	}
}

// Hamburger Custom Styles
.hamburger-container {}